import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./Mortgage.styles";
import { TextInput } from "@components/Inputs";
import { ButtonPrimary, NumericInput } from "@components/Reusables";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { TypeHouseSelector } from "@components/Inputs/SingleSelectors/Services/TypeHouseSelector";
import { HouseStateSelector } from "@components/Inputs/SingleSelectors/Services/HouseStateSelector";
import { useForm } from "@hooks";

interface AutomotiveProps {
  className?: string;
  setStep: (value: number) => void;
  setNumberStep?: (value: number) => void;
}
const MortgageStep1 = ({
  className,
  setStep,
  setNumberStep,
}: AutomotiveProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const [form] = useForm({});

  const [typeHouse, setTypeHouse] = useState("");
  const [comune, setComune] = useState("");
  const [state, setState] = useState("");
  const [pie, setPie] = useState(0);
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");

  const enabledContinue =
    typeHouse.length > 0 &&
    state.length > 0 &&
    comune.length > 0 &&
    pie > 0 &&
    term.length > 0 &&
    value.length > 0;

  const onClickContinue = useCallback(() => {
    form.newMortgageCredit = {
      ...form.newMortgageCredit,
      tipoDeVivienda: typeHouse,
      estado: state,
      columna: comune,
      valor: value,
      pie: pie,
      plazo: term,
    };
  }, [form, typeHouse, comune, state, pie, term, value]);

  return (
    <div className={`${className || ""}`}>
      {!isMobile ? (
        <div className={classes.title}>
          ¡Siempre es bueno proteger tu casa ante accidentes e imprevistos!
        </div>
      ) : (
        <div className={`${classes.title} ${classes.uppercase}`}>
          Crédito hipotecario
        </div>
      )}
      <section className={classes.containerList}>
        <div>
          <div className="containerInput">
            <span>Tipo de vivienda</span>
            <TypeHouseSelector
              className="select"
              onChange={(e) => {
                setTypeHouse(e.target.value as string);
              }}
              value={typeHouse}
            />
          </div>
          <div className="containerInput">
            <span>Estado</span>{" "}
            <HouseStateSelector
              className="select"
              onChange={(e) => {
                setState(e.target.value as string);
              }}
              value={state}
            />
          </div>
          <div className="containerInput">
            <span>Comuna</span>
            <TextInput
              className="select"
              onChange={(e) => {
                setComune(e.target.value as string);
              }}
              name="a"
              type={"number"}
              value={comune}
              placeholder="Number"
            />
          </div>
        </div>
        <div>
          <div className="containerInput">
            <span>Valor en UF</span>
            <TextInput
              className="input"
              onChange={(e) => {
                setValue(e.target.value as string);
              }}
              name="a"
              value={value}
              type={"number"}
              placeholder="Number"
            />
          </div>
          <div className="containerInput">
            <span>Tu pie</span>
            <NumericInput
              className="input"
              value={pie}
              onChange={(e) => {
                setPie(e.target.value as number);
              }}
              name="a"
              placeholder="Number"
            />
          </div>
          <div className="containerInput">
            <span>Plazo (opcional)</span>
            <TextInput
              value={term}
              className="select"
              onChange={(e) => {
                setTerm(e.target.value as string);
              }}
              name="a"
              type={"number"}
              placeholder="Number"
            />
          </div>
        </div>
      </section>{" "}
      <div className={classes.button}>
        <ButtonPrimary
          className={classes.buttonPrimary}
          noDegraded
          disabled={!enabledContinue}
          text="Enviar"
          onClick={() => {
            onClickContinue();
            setStep(0);
            setNumberStep && setNumberStep(-1);
          }}
        />
      </div>
    </div>
  );
};
export default MortgageStep1;
