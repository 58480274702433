import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  list: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem 2rem",
    lineHeight: 1.71,
  },
  subContainerCenter: {
    width: "40rem",
    margin: "0 auto",
    textAlign: "center",
  },
  subContainer: {
    width: "40rem",
    margin: "0 auto",
  },
  containerItem: {
    display: "flex",
    textAlign: "start",
    gap: "2.5rem",
    "& .item": {
      width: "17.8rem",
    },
  },
  centerButton: {
    margin: "3rem auto 0",
    width: "17.8rem",
  },
  title: {
    fontSize: "0.75rem",
    color: theme.palette.text.secondary,
    width: "27rem",
    fontWeight: 600,
    textAlign: "center",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
export default useStyles;
