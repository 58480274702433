import useGeneralStyles from "@components/Services/styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import React, { useCallback, useContext, useState } from "react";
import useStyles from "./ScheduleOrAnalyze.styles";
import { useCurrentUser } from "@apollo";
import { LoadingContext } from "@context";
import { useApolloClient } from "@apollo/client";
import { useForm } from "@hooks";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface newCreditProps {
  setFinishSave: (value: any) => void;
  setAnalyzeFinance: (value: boolean) => void;
  closeAll?: () => void;
  type?: string;
}

const ScheduleOrAnalyze: React.FC<newCreditProps> = ({
  setFinishSave,
  setAnalyzeFinance,
  closeAll,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [newCredit, setNewCredit] = useState(-1);
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();
  const [form] = useForm({});

  const onSend = useCallback(() => {
    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: any = "El usuario desea agendar una reunión de análisis";
    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
    window.open("https://calendly.com/rokinapp/sesioninicial", "_blank");
  }, [currentUser?.name, form]);

  //  const [consolidate, setConsolidate] = useState(-1);

  return (
    <section className={classesGeneral.container}>
      <section className={classes.subContainerCenter}>
        <section
          className={`${classesGeneral.principalTitle} ${classesGeneral.noUppercase}`}
        >
          Tenemos 2 opciones para ti:
        </section>
        {list2.map((item) => (
          <ServiceButton
            onClick={() => {
              item.id === 0 &&
                setFinishSave({
                  Notification48hs: false,
                  Notification24hs: true,
                });
              item.id === 0 && onSend();
              closeAll && closeAll();
              item.id === 1 && setAnalyzeFinance(true);
            }}
            key={item.id}
            name={item.name}
          />
        ))}
      </section>
    </section>
  );
};
export default ScheduleOrAnalyze;

const list2 = [
  {
    id: 0,
    name: "Agendar una reunión de análisis",
    onClick: () => {},
  },
  {
    id: 1,
    name: "Analicen mis finanzas según los datos de mis cuentas",
    onClick: () => {},
  },
];
