import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useState } from "react";
import useStyles from "./ConsumptionStep1.styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import { useForm } from "@hooks";
import RemodelingHouseStep1 from "./RemodelationHouse/Step1";
import CreditAmount from "../CreditAmount/CreditAmount";
import QuotePossibilityStep from "../QuotePossibility/QuotePossibility";
import HeaderServices from "@components/Services/Layout/Header/Header";
import NoCreditAmount from "../NoCreditAmount/NoCreditAmount";
import ScheduleOrAnalyze from "@components/Services/Credits/Credits/Credits/Searching/NewCredit/ConsuptionContainer/ScheduleOrAnalyze/ScheduleOrAnalyze";
import AnalyzeFinance from "@components/Services/Credits/Credits/Credits/Searching/NewCredit/ConsuptionContainer/AnalyzeFinance/AnalyzeFinance";
import BuyOption from "@components/Services/Credits/Credits/Credits/Searching/NewCredit/ConsuptionContainer/ConsumptionOptions/Buy";
import TripOptions from "@components/Services/Credits/Credits/Credits/Searching/NewCredit/ConsuptionContainer/ConsumptionOptions/Trip";
import DebtOptions from "@components/Services/Credits/Credits/Credits/Searching/NewCredit/ConsuptionContainer/ConsumptionOptions/Debt";

interface ConsumptioOptionsProps {
  setStep1?: (value: number) => void;
  setFinishSave: (value: any) => void;
  setTypeStep: React.Dispatch<React.SetStateAction<number>>;
}

const ConsumptionStep1: React.FC<ConsumptioOptionsProps> = ({
  setStep1,
  setFinishSave,
  setTypeStep,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [form] = useForm({});
  const [stepTrip, setStepTrip] = useState(-1);
  const list = [
    {
      id: 0,
      name: "Remodelar la casa",
      onClick: () => {
        form.newConsumptionCredit = "";
      },
    },
    {
      id: 1,
      name: "Comprar tecnología",
      onClick: () => {
        form.newConsumptionCredit = "";
      },
    },
    {
      id: 2,
      name: "Viaje - vacaciones",
      onClick: () => {
        form.newConsumptionCredit = "";
      },
    },
    {
      id: 3,
      name: "Pagar deudas",
      onClick: () => {
        form.newConsumptionCredit = "";
      },
    },
    {
      id: 4,
      name: "Otro",
      onClick: () => {},
      disabled: true,
    },
  ];

  const [step, setStep] = useState(-1);
  const [subStep, setSubstep] = useState(-1);
  const [isOpenCreditAmount, setOpenCreditAmount] = useState(false);
  const [isOpenQuotePossibility, setOpenQuotePossibility] = useState(false);
  const [isExplainCreditReason, setExplainCreditReason] = useState(false);
  const [isScheduleOrAnalyzeStep, setScheduleOrAnalyzeStep] = useState(false);
  const [isScheduleOrAnalyzeStep2, setScheduleOrAnalyzeStep2] = useState(false);
  const [idAnalyzeFinance, setAnalyzeFinance] = useState(false);

  let Consumption;
  const closeAll = useCallback(() => {
    setAnalyzeFinance(false);
    setOpenCreditAmount(false);
    setOpenQuotePossibility(false);
    setExplainCreditReason(false);
    setScheduleOrAnalyzeStep2(false);
    setScheduleOrAnalyzeStep(false);
  }, [
    idAnalyzeFinance,
    isScheduleOrAnalyzeStep2,
    isScheduleOrAnalyzeStep,
    isOpenCreditAmount,
    isOpenQuotePossibility,
    isExplainCreditReason,
    isScheduleOrAnalyzeStep,
  ]);

  switch (step) {
    case -1:
      Consumption = (
        <>
          <HeaderServices
            title="CRÉDITOS"
            onBackClick={() => setTypeStep(-1)}
            steps={["Nuevo crédito", "Consumo"]}
          />
          <div className={`${classesGeneral.container}`}>
            <section>
              <div className={`${classes.general}`}>
                <div
                  className={`${classesGeneral.principalTitle} ${classes.align}`}
                >
                  ¿Para qué necesitas el crédito?
                </div>
                <div className={classes.listServices}>
                  {list.map((item) => (
                    <ServiceButton
                      onClick={() => {
                        item.onClick();
                        form.newConsumptionCredit = {
                          ...form.newConsumptionCredit,
                          motivo: item.name,
                        };
                        setStep(item.id);
                      }}
                      title={item.title}
                      key={item.id}
                      name={item.name}
                      disabled={item.disabled || false}
                    />
                  ))}
                </div>
              </div>
            </section>
          </div>
        </>
      );
      break;
    case 0:
      if (
        !isOpenCreditAmount &&
        !isOpenQuotePossibility &&
        !isScheduleOrAnalyzeStep
      ) {
        Consumption = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => setStep(step - 1)}
              steps={["Nuevo crédito", "Consumo", "Casa"]}
            />
            <div className={`${classesGeneral.container}`}>
              <RemodelingHouseStep1
                setOpenCreditAmount={setOpenCreditAmount}
                setScheduleOrAnalyzeStep={setScheduleOrAnalyzeStep}
                setSubstep={setSubstep}
                setExplainCreditReason={setExplainCreditReason}
              />
            </div>
          </>
        );
      }
      if (isOpenCreditAmount) {
        Consumption = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                setOpenCreditAmount(false);
              }}
              steps={["Nuevo crédito"]}
            />
            <div className={`${classesGeneral.container}`}>
              <CreditAmount
                setOpenCreditAmount={setOpenCreditAmount}
                setOpenQuotePossibility={setOpenQuotePossibility}
                setExplainCreditReason={setExplainCreditReason}
              />
            </div>
          </>
        );
      }
      if (isScheduleOrAnalyzeStep) {
        Consumption = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                setStep(-1);
              }}
              steps={["Nuevo créditos"]}
            />
            <div>
              <NoCreditAmount
                setOpenQuotePossibility={setOpenQuotePossibility}
                setExplainCreditReason={setExplainCreditReason}
                closeAll={closeAll}
              />
            </div>
          </>
        );
      }
      if (isExplainCreditReason) {
        Consumption = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                closeAll();
                setOpenCreditAmount(true);
              }}
              steps={["Nuevo crédito"]}
            />
            <div className={`${classesGeneral.container}`}>
              <QuotePossibilityStep
                setScheduleOrAnalyzeStep2={setScheduleOrAnalyzeStep2}
                setExplainCreditReason={setExplainCreditReason}
                setFinishSave={setFinishSave}
              />
            </div>
          </>
        );
      }
      if (isScheduleOrAnalyzeStep2) {
        Consumption = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                closeAll();
                setScheduleOrAnalyzeStep2(false);
                setExplainCreditReason(true);
              }}
              steps={["Nuevo crédito"]}
            />
            <ScheduleOrAnalyze
              setAnalyzeFinance={setAnalyzeFinance}
              setFinishSave={setFinishSave}
              closeAll={closeAll}
            />
          </>
        );
      }
      if (idAnalyzeFinance) {
        Consumption = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                closeAll();
                setAnalyzeFinance(false);
                setScheduleOrAnalyzeStep2(true);
              }}
              steps={["Nuevo crédito"]}
            />
            <div className={`${classesGeneral.container}`}>
              <AnalyzeFinance setFinishSave={setFinishSave} />
            </div>
          </>
        );
      }
      break;
    case 1:
      if (!isOpenCreditAmount) {
        Consumption = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => setStep(-1)}
              steps={["Nuevo crédito", "Consumo", "Comprar"]}
            />
            <div>
              <BuyOption setOpenCreditAmount={setOpenCreditAmount} />
            </div>
          </>
        );
      }
      if (isOpenCreditAmount) {
        Consumption = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => setOpenCreditAmount(false)}
              steps={["Nuevo crédito"]}
            />
            <div className={`${classesGeneral.container}`}>
              <CreditAmount
                type="Technology"
                setOpenCreditAmount={setOpenCreditAmount}
                setOpenQuotePossibility={setOpenQuotePossibility}
                setExplainCreditReason={setExplainCreditReason}
              />
            </div>
          </>
        );
      }
      if (isExplainCreditReason) {
        Consumption = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                closeAll();
                setOpenCreditAmount(true);
              }}
              steps={["Nuevo crédito"]}
            />
            <div className={`${classesGeneral.container}`}>
              <QuotePossibilityStep
                type="Technology"
                setScheduleOrAnalyzeStep2={setScheduleOrAnalyzeStep2}
                setExplainCreditReason={setExplainCreditReason}
                setFinishSave={setFinishSave}
              />
            </div>
          </>
        );
      }
      if (isScheduleOrAnalyzeStep2) {
        Consumption = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                closeAll();
                setExplainCreditReason(true);
              }}
              steps={["Nuevo crédito"]}
            />
            <ScheduleOrAnalyze
              setAnalyzeFinance={setAnalyzeFinance}
              setFinishSave={setFinishSave}
              closeAll={closeAll}
            />
          </>
        );
      }
      if (idAnalyzeFinance) {
        Consumption = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                setAnalyzeFinance(false);
              }}
              steps={["Nuevo crédito"]}
            />
            <div className={`${classesGeneral.container}`}>
              <AnalyzeFinance setFinishSave={setFinishSave} />
            </div>
          </>
        );
      }
      break;
    case 2:
      if (stepTrip !== 0) {
        Consumption = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                stepTrip === 1 ? setStepTrip(-1) : setStep(-1);
              }}
              steps={["Nuevo crédito", "Consumo", "Viaje / Vacaciones"]}
            />
            <div>
              <TripOptions
                setFinishSave={setFinishSave}
                setOpenCreditAmount={setOpenCreditAmount}
                setStepTrip={setStepTrip}
                stepTrip={stepTrip}
                setExplainCreditReason={setExplainCreditReason}
              />
            </div>
          </>
        );
      }
      if (stepTrip === 0) {
        if (isOpenCreditAmount) {
          Consumption = (
            <>
              <HeaderServices
                title="CRÉDITOS"
                onBackClick={() => {
                  setStepTrip(-1);
                }}
                steps={["Nuevo crédito"]}
              />
              <div className={`${classesGeneral.container}`}>
                <CreditAmount
                  type="Technology"
                  setOpenCreditAmount={setOpenCreditAmount}
                  setOpenQuotePossibility={setOpenQuotePossibility}
                  setExplainCreditReason={setExplainCreditReason}
                />
              </div>
            </>
          );
        }
        if (isExplainCreditReason) {
          Consumption = (
            <>
              <HeaderServices
                title="CRÉDITOS"
                onBackClick={() => {
                  closeAll();
                  setOpenCreditAmount(true);
                  setStepTrip(0);
                }}
                steps={["Nuevo crédito", "Consumo", "Viaje / Vacaciones"]}
              />
              <div className={`${classesGeneral.container}`}>
                <QuotePossibilityStep
                  setScheduleOrAnalyzeStep2={setScheduleOrAnalyzeStep2}
                  setExplainCreditReason={setExplainCreditReason}
                  setFinishSave={setFinishSave}
                />
              </div>
            </>
          );
        }
        if (isScheduleOrAnalyzeStep2) {
          Consumption = (
            <>
              <HeaderServices
                title="CRÉDITOS"
                onBackClick={() => {
                  closeAll();
                  setExplainCreditReason(true);
                }}
                steps={["Nuevo crédito"]}
              />
              <ScheduleOrAnalyze
                setAnalyzeFinance={setAnalyzeFinance}
                setFinishSave={setFinishSave}
                closeAll={closeAll}
              />
            </>
          );
        }
        if (idAnalyzeFinance) {
          Consumption = (
            <>
              <HeaderServices
                title="CRÉDITOS"
                onBackClick={() => {
                  closeAll();
                  setStepTrip(0);
                  setAnalyzeFinance(false);
                  setScheduleOrAnalyzeStep2(true);
                }}
                steps={["Nuevo crédito"]}
              />
              <div className={`${classesGeneral.container}`}>
                <AnalyzeFinance setFinishSave={setFinishSave} />
              </div>
            </>
          );
        }
      }
      break;
    case 3:
      Consumption = (
        <>
          <DebtOptions setFinishSave={setFinishSave} setStep={setStep} />;
        </>
      );

      break;
    default:
      break;
  }

  return (
    <>
      <div>{Consumption}</div>
    </>
  );
};
export default ConsumptionStep1;
