import useGeneralStyles from "@components/Services/styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import React from "react";
import useStyles from "./Searching.styles";
import HeaderServices from "@components/Services/Layout/Header/Header";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

interface creditsProps {
  setStepContinue: (value: number) => void;
  setStepCredit: (value: number) => void;
  setYouLookingFor: React.Dispatch<
    React.SetStateAction<{
      newCredit: boolean;
      refinance: boolean;
      consolidateCredit: boolean;
    }>
  >;
}

const CreditsSearching: React.FC<creditsProps> = ({
  setStepContinue,
  setStepCredit,
  setYouLookingFor,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();

  return (
    <>
      <HeaderServices
        title="CRÉDITOS"
        onBackClick={() => navigate(Route.services)}
      />
      <section className={classesGeneral.container}>
        <div className={classesGeneral.title}>
          Si buscas algo relacionado a créditos, nosotros te podemos ayudar a
          encontrar la mejor alternativa.
        </div>
        <div className={classesGeneral.principalTitle}>
          ¿Qué estás buscando?
        </div>
        <div className={classes.containerItems}>
          {list.map((item) => (
            <ServiceButton
              onClick={() => {
                setStepContinue(item.id);
                setStepCredit(item.id);
                setYouLookingFor({
                  newCredit: item.id === 0,
                  refinance: item.id === 1,
                  consolidateCredit: item.id === 2,
                });
              }}
              key={item.id}
              name={item.name}
            />
          ))}
        </div>
      </section>
    </>
  );
};
export default CreditsSearching;

const list = [
  {
    id: 0,
    name: "Un nuevo crédito",
  },
  {
    id: 1,
    name: "Refinanciar un crédito",
  },
  {
    id: 2,
    name: "Consolidar un crédito",
  },
];
