import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem 2.5rem",
    "& .input": {
      display: "flex",
      flexDirection: "column",
    },
  },
  general: {
    margin: "0 auto",
  },
  listServices: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem 2.5rem",
  },

  data: {
    margin: "0 auto",
    width: "206px",
  },
  containerButton: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "2rem",
    "& .input": {
      width: "17.8rem!important",
      maxWidth: "auto",
    },
  },
  // perfecto
  subContainerCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    width: "40rem",
    margin: "0 auto",
    [theme.breakpoints.down(1200)]: {
      width: 320,
    },
  },
  subContainerCenterTrip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    width: "38rem",
    margin: "0 auto",
  },
  flexTrip: {
    display: "flex",
    justifyContent: "space-between",
    width: "30rem",
    gap: "2rem",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
      gap: 0,
      width: "100%",
    },
    "&>div": {
      minWidth: "17.75rem",
      marginTop: "2rem",
      [theme.breakpoints.down(1200)]: { marginTop: "1rem" },
    },
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "25rem",
    "&>div": {
      width: "11.25rem",
    },
  },
  flexTrip2: {
    display: "flex",
    flexDirection: "column",
    "& span": {
      marginBottom: "0.2rem",
    },
    "&>div": {
      width: "11.25rem",
    },
  },
  flexList: {
    display: "flex",
    gap: "1.9rem",
    "& .inpuShort": {
      width: "8.1rem",
    },
    "& .inputLarge": {
      width: "17.8rem",
    },
  },
  flexList1: {
    display: "flex",
    gap: "2.2rem",
    marginBottom: "1.5rem",
    [theme.breakpoints.down(1200)]: { marginBottom: "0.5rem" },
    "& .inpuShort": {
      width: "8.2rem",
    },
    "& .inputLarge": {
      width: "17.8rem",
    },
  },
  center: {
    margin: "auto auto",
  },
  principalTitle: {
    margin: "1.25rem auto",
  },
  title: {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
    width: "27rem",
    textAlign: "center",
  },
  styleSpace: {
    width: "17.8rem",
    maxHeight: "9rem",
    height: "9rem",
    margin: "0 auto",
    border: `1px solid rgba(0, 0, 0, 0.25)`,
    borderRadius: 10,
    padding: "0.5rem",
    color: theme.palette.text.secondary,
  },
  primaryButton: {
    margin: "2rem auto 0",
    width: "17.8rem",
  },
  containerButtonEnd: {
    display: "flex",
    justifyContent: "end",
    marginRight: "1rem",
    marginTop: "4rem",
    "& button": {
      width: "17.8rem",
    },
  },
}));
export default useStyles;
