import React, { useCallback, useContext, useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import useGeneralStyles from "@components/Services/styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import { useCurrentUser } from "@apollo";
import { LoadingContext } from "@context";
import { useApolloClient } from "@apollo/client";
import { useForm } from "@hooks";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface AutomotiveProps {
  className?: string;
  setStep: (value: number) => void;
  setFinishSave: (value: {
    Notification48hs: boolean;
    Notification24hs: boolean;
  }) => void;
}
const MortgageStep2 = ({
  className,
  setStep,
  setFinishSave,
}: AutomotiveProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();
  const [form] = useForm({});

  const onSend = useCallback(() => {
    form.newMortgageCredit = {
      ...form.newMortgageCredit,
      cotizacion: "general",
    };
    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.newMortgageCredit;
    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
    setFinishSave({
      Notification48hs: true,
      Notification24hs: false,
    });
  }, [currentUser?.name, form]);
  return (
    <div className={`${className || ""}`}>
      <section className={classes.containerAll}>
        {!isMobile ? (
          <div className={`${generalClasses.secondary}`}>
            <span>¡Buenísimo! </span>Cotizaremos las mejores opciones que
            existan en el mercado. Si quieres una cotización más detallada,
            debes subir un par de archivos.
          </div>
        ) : (
          <div className={`${generalClasses.secondary} ${classes.secondary}`}>
            ¡Buenísimo! Cotizaremos las mejores opciones que existan en el
            mercado.
            <div>
              <br />
              <span>CRÉDITOS HIPOTECARIOS</span>
              <br /> <br />
            </div>
            <div>
              Si quieres una cotización más detallada, debes subir un par de
              archivos.
            </div>
          </div>
        )}
        <div className={classes.containerItems}>
          {list.map((item) => (
            <ServiceButton
              onClick={() => {
                item.id === 0 ? setStep(1) : onSend();
              }}
              title={item.title}
              key={item.id}
              name={item.name}
            />
          ))}
        </div>
      </section>
    </div>
  );
};
export default MortgageStep2;

const list = [
  {
    id: 0,
    title: "Cotización detallada",
    name: "Descripción",
  },
  {
    id: 1,
    title: "Cotización general",
    name: "Descripción",
  },
];
