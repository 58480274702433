import useGeneralStyles from "@components/Services/styles";
import React, { useState } from "react";
import useStyles from "./CreditAmount.styles";
import { ButtonPrimary } from "@components/Reusables";
import { TextInput } from "@components/Inputs";
import { useForm } from "@hooks";

interface ConsumptionProps {
  setOpenQuotePossibility: (value: boolean) => void;
  setOpenCreditAmount: (value: boolean) => void;
  setExplainCreditReason: (value: boolean) => void;
  type?: string;
  setStepDebt?: (v: number) => void;
  setStepDebtFamily?: (value: number) => void;
}

const CreditAmount: React.FC<ConsumptionProps> = ({
  setOpenQuotePossibility,
  setOpenCreditAmount,
  setExplainCreditReason,
  type,
  setStepDebt,
  setStepDebtFamily,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [form] = useForm({});
  const [value, setValue] = useState(0);
  return (
    <section>
      <div className={classes.data}>
        <div className={classes.title}>Monto del crédito que necesitas</div>
        <TextInput
          className={classes.input}
          onChange={(e) => {
            setValue(e.target.value as number);
          }}
          type="number"
          placeholder="Number"
        />
      </div>
      <div className={classes.containerButtons}>
        <ButtonPrimary
          className="button"
          noDegraded
          disabled={!(value > 0)}
          onClick={() => {
            setOpenCreditAmount(false);
            setOpenQuotePossibility(false);
            if (!type) {
              form.newConsumptionCredit = {
                ...form.newConsumptionCredit,
                costoAproximado: value,
              };
            } else {
              form.newConsumptionCredit = {
                ...form.newConsumptionCredit,
                monto: value,
              };
            }
            setExplainCreditReason(true);
            setStepDebt && setStepDebt(3);
            setStepDebtFamily && setStepDebtFamily(-1);
          }}
          text="Enviar"
        />
      </div>
    </section>
  );
};
export default CreditAmount;
