import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./Mortgage.styles";
import { useForm } from "@hooks";
import MortgageStep1 from "./MortgageStep1";
import MortgageStep2 from "./MortgageStep2/MortgageStep2";
import MortgageStep3 from "./MortgageStep3/MortgageStep3";
import HeaderServices from "@components/Services/Layout/Header/Header";

interface AutomotiveProps {
  className?: string;
  setFinishSave: (value: any) => void;
  setTypeStep: React.Dispatch<React.SetStateAction<number>>;
}
const Mortgage = ({
  className,
  setFinishSave,
  setTypeStep,
}: AutomotiveProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const [form] = useForm({});
  const [step, setStep] = useState(-1);
  let mortgage;

  switch (step) {
    case -1:
      mortgage = <MortgageStep1 setStep={setStep} />;
      break;
    case 0:
      mortgage = (
        <MortgageStep2 setStep={setStep} setFinishSave={setFinishSave} />
      );
      break;
    case 1:
      mortgage = <MortgageStep3 setFinishSave={setFinishSave} />;
      break;
    default:
      break;
  }

  return (
    <>
      <HeaderServices
        title="CRÉDITOS"
        onBackClick={() => (step === -1 ? setTypeStep(-1) : setStep(step - 1))}
        steps={["Nuevo crédito"]}
      />
      <div className={`${className || ""} ${classes.container}`}>
        {mortgage}
      </div>
    </>
  );
};
export default Mortgage;
