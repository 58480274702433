import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./index.styles";
import useGeneralStyles from "../styles";
import ServiceButton from "../ServiceButton/ServiceButton";
import Mortgage from "./Mortgage";
import Consumption from "./Consumption";
import HeaderServices from "../Layout/Header/Header";
import MortgageStep1 from "./Mortgage/MortgageStep1";
import AutomotiveService from "../Credits/NewCredit/Automotriz/Step1/Step1";
import AutomotrizStep2 from "../Credits/NewCredit/Automotriz/Step2/Step2";

interface YouSeekProps {
  setFinishSave: (value: any) => void;
  youLookingFor: {
    newCredit: boolean;
    refinance: boolean;
    consolidateCredit: boolean;
  };
  setStepContinue: React.Dispatch<React.SetStateAction<number>>;
}
const ServicesYouLookingFor = ({
  setFinishSave,
  setStepContinue,
}: YouSeekProps) => {
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();

  const [typeStep, setTypeStep] = useState(-1);
  const [stepAutomotore, setStepAutomotore] = useState(-1);

  const list = [
    {
      id: 0,
      title: "Hipotecario",
      name: "Hogar o inversión",
      onClick: () => {},
    },
    {
      id: 1,
      title: "Consumo",
      name: "Quiero comprar",
      onClick: () => {},
    },
    {
      id: 2,
      title: "Automotriz",
      name: "Auto nuevo o renovación",
      onClick: () => {},
    },
  ];

  let StepSearchingSelect;

  switch (typeStep) {
    case -1:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="CRÉDITOS"
            onBackClick={() =>
              typeStep === -1 ? setStepContinue(-1) : setTypeStep(typeStep - 1)
            }
            steps={["Nuevo crédito"]}
          />
          <section className={classesGeneral.container}>
            <div
              className={`${classesGeneral.principalTitle} ${classes.align}`}
            >
              Tipo de crédito
            </div>
            <br />
            <div className={classes.containerItems}>
              {list.map((item) => (
                <ServiceButton
                  onClick={() => {
                    setTypeStep(item.id);
                  }}
                  title={item.title}
                  key={item.id}
                  name={item.name}
                />
              ))}
            </div>
          </section>
        </>
      );
      break;
    case 0:
      StepSearchingSelect = (
        <Mortgage setTypeStep={setTypeStep} setFinishSave={setFinishSave} />
      );
      break;
    case 1:
      StepSearchingSelect = (
        <Consumption setTypeStep={setTypeStep} setFinishSave={setFinishSave} />
      );
      break;
    case 2:
      if (stepAutomotore === -1)
        StepSearchingSelect = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                setTypeStep(-1);
              }}
              steps={["Nuevo crédito", "Automotriz"]}
            />
            <AutomotiveService
              StepAutomotore={setStepAutomotore}
              setFinishSave={setFinishSave}
            />
          </>
        );
      if (stepAutomotore === 0)
        StepSearchingSelect = (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                setStepAutomotore(-1);
              }}
              steps={["Nuevo crédito", "Automotriz"]}
            />
            <div>
              <AutomotrizStep2
                setStepAutomotore={setStepAutomotore}
                setFinishSave={setFinishSave}
              />
            </div>
          </>
        );
      break;
    default:
      break;
  }

  return <div>{StepSearchingSelect}</div>;
};
export default ServicesYouLookingFor;
