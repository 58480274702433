import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useState } from "react";
import useStyles from "./Styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import HeaderServices from "@components/Services/Layout/Header/Header";
import OtherCredit from "../../../NewCredit/Debt/OtherCredit/OtherCredit";
import CreditAmount from "@components/Services/LookingFor/Consumption/CreditAmount/CreditAmount";
import QuotePossibilityStep from "@components/Services/LookingFor/Consumption/QuotePossibility/QuotePossibility";
import AnalyzeFinance from "../AnalyzeFinance/AnalyzeFinance";
import ScheduleOrAnalyze from "../ScheduleOrAnalyze/ScheduleOrAnalyze";
import AnalyzeDebt from "../../Debt/AnalyzeDebt/AnalyzeDebt";
import CreditCard from "../../Debt/CreditCard/CreditCard";
import FamilyDebt from "../../Debt/FamilyDebt/FamilyDebt";

interface ConsumptioOptionsProps {
  setStep: (value: number) => void;
  setFinishSave: (value: any) => void;
}

const DebtOptions: React.FC<ConsumptioOptionsProps> = ({
  setStep,
  setFinishSave,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [servicesSelect, setServicesSelect] = useState(-1);
  const [subServicesSelect, setSubServicesSelect] = useState(-1);
  const [stepDebt, setStepDebt] = useState(-1);
  const [stepDebt2, setStepDebt2] = useState(-1);
  const [stepDebtFamily, setStepDebtFamily] = useState(-1);

  const [isOpenCreditAmount, setOpenCreditAmount] = useState(false);
  const [isOpenPerfect, setOpenPerfect] = useState(false);
  const [idAnalyzeFinance, setAnalyzeFinance] = useState(false);

  const [isOpenQuotePossibility, setOpenQuotePossibility] = useState(false);
  const [isExplainCreditReason, setExplainCreditReason] = useState(false);
  const list4 = [
    {
      id: 0,
      name: "Otro crédito de consumo",
      onClick: () => {
        setStepDebt(0);
      },
    },
    {
      id: 1,
      name: "Tarjeta de crédito",
      onClick: () => {
        setStepDebt2(1);
        setStepDebt(-2);
      },
    },
    {
      id: 2,
      name: "Deuda con familiar o amigo",
      onClick: () => {
        setStepDebt2(-2);
        setStepDebt(-2);
        setStepDebtFamily(0);
      },
    },
    {
      id: 3,
      name: "Otro",
      onClick: () => {},
      disabled: true,
    },
  ];
  const closeAll = useCallback(() => {
    setAnalyzeFinance(false);
    setOpenCreditAmount(false);
    setOpenQuotePossibility(false);
    setExplainCreditReason(false);
    setOpenPerfect(false);
  }, [
    idAnalyzeFinance,
    isOpenPerfect,
    isOpenCreditAmount,
    isOpenQuotePossibility,
    isExplainCreditReason,
  ]);
  return (
    <>
      <section>
        {stepDebt === -1 && (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                setStep(-1);
              }}
              steps={["Nuevo crédito", "Consumo", "Deudas"]}
            />

            <section className={classesGeneral.container}>
              <div className={`${classesGeneral.secondary}`}>
                Pedir un crédito de consumo para pagar deudas no siempre es una
                buena opción.
              </div>
              <div className={`${classesGeneral.principalTitle}`}>
                qué deuda quieres pagar
              </div>
              <section className={classes.list}>
                {list4.map((item) => (
                  <ServiceButton
                    onClick={() => {
                      item.onClick();
                      setServicesSelect(item.id);
                    }}
                    key={item.id}
                    name={item.name}
                    disabled={item?.disabled || false}
                  />
                ))}
              </section>
            </section>
          </>
        )}
        {stepDebt === 0 && (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => setStepDebt(-1)}
              steps={["Nuevo crédito", "Consumo", "Otro crédito"]}
            />
            <section className={classesGeneral.container}>
              <OtherCredit setStepDebt={setStepDebt} />
            </section>
          </>
        )}

        {stepDebt === 2 && (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => setStepDebt(0)}
              steps={["Nuevo crédito"]}
            />
            <div className={`${classesGeneral.container}`}>
              <CreditAmount
                setOpenCreditAmount={setOpenCreditAmount}
                setOpenQuotePossibility={setOpenQuotePossibility}
                setExplainCreditReason={setExplainCreditReason}
                setStepDebt={setStepDebt}
                setStepDebtFamily={setStepDebtFamily}
              />
            </div>
          </>
        )}
        {isExplainCreditReason && (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                setExplainCreditReason(false);
                setOpenCreditAmount(true);
                setStepDebt(2);
              }}
              steps={["Nuevo crédito"]}
            />
            <div className={`${classesGeneral.container}`}>
              <QuotePossibilityStep
                setScheduleOrAnalyzeStep2={setOpenPerfect}
                setExplainCreditReason={setExplainCreditReason}
                setFinishSave={setFinishSave}
              />
            </div>
          </>
        )}
        {isOpenPerfect && (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                setOpenPerfect(false);
                setExplainCreditReason(true);
              }}
              steps={["Nuevo crédito"]}
            />
            <div>
              <ScheduleOrAnalyze
                setAnalyzeFinance={setAnalyzeFinance}
                setFinishSave={setFinishSave}
                closeAll={closeAll}
              />
            </div>
          </>
        )}
        {idAnalyzeFinance && (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                setOpenCreditAmount(false);
                setAnalyzeFinance(false);
                setOpenPerfect(true);
              }}
              steps={["Nuevo crédito"]}
            />
            <div className={`${classesGeneral.container}`}>
              <AnalyzeFinance setFinishSave={setFinishSave} />
            </div>
          </>
        )}
        {stepDebt === 1 && (
          <>
            <HeaderServices
              title=""
              onBackClick={() => setStepDebt(0)}
              steps={["ANÁLISIS DE DEUDA"]}
            />
            <div className={`${classesGeneral.container}`}>
              <AnalyzeDebt setFinishSave={setFinishSave} />
            </div>
          </>
        )}
        {(stepDebt2 === 1 || stepDebt2 === 2) && (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                stepDebt2 === 1
                  ? setStepDebt2(0)
                  : stepDebt2 === 2
                  ? setStepDebt2(1)
                  : "";
                stepDebt2 === 1 && setStepDebt(-1);
              }}
              steps={["Nuevo crédito", "Consumo", "Tarjeta de crédito"]}
            />
            <CreditCard
              setStep={setStepDebt2}
              step={stepDebt2}
              setFinishSave={setFinishSave}
            />
          </>
        )}
        {stepDebt2 === 3 && (
          <>
            <HeaderServices
              title=""
              onBackClick={() => setStepDebt2(2)}
              steps={["ANÁLISIS DE DEUDA"]}
            />{" "}
            <div className={`${classesGeneral.container}`}>
              <AnalyzeDebt setFinishSave={setFinishSave} />
            </div>
          </>
        )}
        {stepDebtFamily === 0 && (
          <>
            {" "}
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => {
                setStepDebt(-1);
                setStepDebtFamily(-1);
              }}
              steps={["Nuevo crédito", "Consumo", "Amigo o familiar"]}
            />{" "}
            <FamilyDebt
              setFinishSave={setFinishSave}
              setStepDebtFamily={setStepDebtFamily}
            />
          </>
        )}
        {stepDebtFamily === 1 && (
          <>
            <HeaderServices
              title="CRÉDITOS"
              onBackClick={() => setStepDebtFamily(0)}
              steps={["Nuevo crédito"]}
            />{" "}
            <div className={`${classesGeneral.container}`}>
              <CreditAmount
                setStepDebtFamily={setStepDebtFamily}
                setOpenCreditAmount={setOpenCreditAmount}
                setOpenQuotePossibility={setOpenQuotePossibility}
                setExplainCreditReason={setExplainCreditReason}
                setStepDebt={setStepDebt}
              />
            </div>
          </>
        )}
      </section>
    </>
  );
};
export default DebtOptions;
