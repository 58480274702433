import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem 2.5rem",
    "& .input": {
      display: "flex",
      flexDirection: "column",
    },
  },
  title: {
    fontSize: "0.75rem",
    fontWeight: 600,
    margin: "0 auto 1rem",
    color: theme.palette.text.secondary,
  },
  data: {
    margin: "0 auto",
    width: "206px",
  },
  containerButtons: {
    display: "flex",
    marginTop: "3rem",
    gap: "3rem",
    justifyContent: "center",
    "& .button": {
      width: "17.8rem",
    },
  },
}));
export default useStyles;
