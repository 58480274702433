import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem 2.5rem",
    "& .input": {
      display: "flex",
      flexDirection: "column",
    },
  },
  general: {
    margin: "0 auto",
  },
  listServices: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem 2.5rem",
  },

  data: {
    margin: "0 auto",
    width: "206px",
  },
  containerButton: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "2rem",
    "& .input": {
      width: "17.8rem!important",
      maxWidth: "auto",
    },
  },
  // perfecto
  subContainerCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    width: "40rem",
    margin: "0 auto",
    [theme.breakpoints.down(1200)]: {
      width: 300,
    },
  },
  subContainerCenterTrip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    width: "38rem",
    margin: "0 auto",
  },
  flexTrip: {
    display: "flex",
    justifyContent: "space-between",
    width: "30rem",
    gap: "2rem",
    "&>div": {
      minWidth: "17.75rem",
      marginTop: "2rem",
    },
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    gap: "5rem",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
      gap: 0,
      margin: "0 auto",
      width: 300,
    },
    "&>div": {
      marginTop: "1rem",
    },
  },
}));
export default useStyles;
