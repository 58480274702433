import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 4rem 5rem",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      marginBottom: "0",
    },
  },
  containerButtons: {
    display: "flex",
    gap: "3rem",
    justifyContent: "end",
    alignItems: "center",
    marginRight: "1.2rem",
    marginTop: "3rem",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column-reverse",
      gap: "1rem",
    },
    "& .button": {
      width: "17.8rem",
      marginTop: "0",
    },
  },
  title: {
    paddingBottom: "1.5rem",
    color: theme.palette.text.secondary,
    fontWeight: 300,
    [theme.breakpoints.down(1300)]: {
      fontSize: "0.75rem",
      width: "287px",
      margin: "0 auto",
    },
  },
  containerList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "2rem 4rem",
    "& >div": {
      display: "flex",
      gap: "2.5rem",
      [theme.breakpoints.down(1300)]: {
        flexDirection: "column",
        gap: "1rem",
      },
    },
    "& >div:last-child": {
      gap: "8rem",
      justifyContent: "start",
      [theme.breakpoints.down(1300)]: {
        gap: "1rem",
      },
    },
    "& .input": {
      width: "12.5rem",
      display: "flex",
      flexDirection: "column",
    },
    "& .select": {
      width: "18rem",
      display: "flex",
      flexDirection: "column",
    },
    "& .containerInput": {
      display: "flex",
      flexDirection: "column",
      color: "#6b6b6b",
      fontFamily: "Montserrat",
      fontWeight: 600,
      [theme.breakpoints.down(1300)]: {
        fontSize: "0.75rem",
        width: "287px",
        margin: "0 auto",
      },
    },
  },
  button: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down(1300)]: {
      fontSize: "1rem",
      width: "287px",
      margin: "0 auto",
      "& button": {
        marginRight: "0",
      },
    },
  },
  buttonPrimary: {
    width: "18rem",
    marginRight: "3rem",
    marginTop: "2rem",
  },
  uppercase: {
    textTransform: "uppercase",
    fontWeight: 500,
  },
}));
export default useStyles;
