import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerText: {
    width: "21.5rem",
    color: theme.palette.text.secondary,
    margin: "2rem auto 0",
  },
  containerText2: {
    width: "17rem",
    color: theme.palette.text.secondary,
    margin: "2rem auto 0",
    fontSize: "0.875rem",
  },
  center: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "0 auto 2rem",
    [theme.breakpoints.down(1200)]: {
      width: 280,
      textAlign: "start",
      fontSize: "0.85rem",
    },
  },
  button: {
    width: "17.8rem",
    fontWeight: 300,
    margin: "0 auto",
    display: "flex",
  },
}));
export default useStyles;
