import useGeneralStyles from "@components/Services/styles";
import React, { useState } from "react";
import { ButtonPrimary } from "@components/Reusables";
import useStyles from "../ScheduleOrAnalyze/ScheduleOrAnalyze.styles";
import { useForm } from "@hooks";

interface ConsumptioOptionsProps {
  setOpenCreditAmount: (value: boolean) => void;
}

const BuyOption: React.FC<ConsumptioOptionsProps> = ({
  setOpenCreditAmount,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [form] = useForm({});

  return (
    <>
      <section className={classesGeneral.container}>
        <section className={classes.subContainerCenter}>
          <div
            className={`${classesGeneral.principalTitle} ${classesGeneral.noUppercase} ${classes.title}`}
          >
            ¿Qué quieres comprar?
          </div>
          <textarea
            className={`${classes.styleSpace}`}
            onChange={(e) => {
              setValue(e.target.value as string);
            }}
            placeholder="Comentario"
          />
          <ButtonPrimary
            className={classes.primaryButton}
            text="Enviar"
            noDegraded
            disabled={!(value.length > 0)}
            onClick={() => {
              form.newConsumptionCredit = {
                categoria: "Tecnología",
                descripcion: value,
              };
              setOpenCreditAmount(true);
            }}
          />
        </section>
      </section>
    </>
  );
};
export default BuyOption;
