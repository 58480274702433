import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useContext, useState } from "react";
import useStyles from "./CreditCard.styles";
import { ButtonPrimary } from "@components/Reusables";
import { TextInput } from "@components/Inputs";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import { useForm } from "@hooks";
import { useApolloClient } from "@apollo/client";
import { useCurrentUser } from "@apollo";
import { LoadingContext } from "@context";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface ConsumptioOptionsProps {
  setStep: (value: number) => void;
  step: number;
  setFinishSave?: (value: any) => void;
}

const CreditCard: React.FC<ConsumptioOptionsProps> = ({
  setStep,
  step,
  setFinishSave,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [form] = useForm({});
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();

  const onSend = useCallback(() => {
    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.newConsumptionCredit;
    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form]);

  const list = [
    {
      id: 0,
      name: "Analizar mis otras deudas",
      onClick: () => {
        setStep(3);
      },
    },
    {
      id: 1,
      name: "Solo esta deuda",
      onClick: () => {
        onSend();
        setFinishSave &&
          setFinishSave({
            Notification48hs: false,
            Notification24hs: true,
          });
      },
    },
  ];
  const [time, setTime] = useState("");
  const [amount, setAmount] = useState("");

  return (
    <>
      {step === 1 ? (
        <section className={classesGeneral.container}>
          <section className={classes.subContainerCenter}>
            <div className={`${classesGeneral.secondary}`}>
              Pedir un crédito de consumo para saldar la tarjeta de crédito
              puede ser buena opción a veces.
            </div>
            <div className={classes.flexTrip}>
              <div>
                <span className={classesGeneral.secondaryTitle}>
                  ¿Cuánto debes?
                </span>
                <TextInput
                  className="input"
                  type={"text"}
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value as string);
                  }}
                />
              </div>
              <div>
                <span className={classesGeneral.secondaryTitle}>
                  ¿Cuánto meses llevas sin pagar?
                </span>
                <TextInput
                  className="input"
                  type={"text"}
                  value={time}
                  onChange={(e) => {
                    setTime(e.target.value as string);
                  }}
                />
              </div>
            </div>

            <ButtonPrimary
              className={classes.primaryButton}
              noDegraded
              disabled={!(amount.length > 0 && time.length > 0)}
              onClick={() => {
                form.newConsumptionCredit = {
                  ...form.newConsumptionCredit,
                  montoDeuda: amount,
                  tiempoDeuda: time,
                };
                setStep(2);
              }}
              text="Enviar"
            />
          </section>
        </section>
      ) : (
        step === 2 && (
          <section className={classesGeneral.container}>
            <section className={classes.subContainerCenter}>
              <div
                className={`${classesGeneral.principalTitle} ${classesGeneral.noUppercase}`}
              >
                ¡Perfecto!
              </div>{" "}
              <div className={`${classesGeneral.secondary}`}>
                Podemos hacer un análisis completo de la situación o resolver
                solo esta deuda.
              </div>
              <div className={classes.centerList}>
                {list.map((item) => (
                  <ServiceButton
                    onClick={() => {
                      item.onClick();
                    }}
                    key={item.id}
                    name={item.name}
                  />
                ))}
              </div>
            </section>
          </section>
        )
      )}
    </>
  );
};
export default CreditCard;
