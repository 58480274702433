import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem 2.5rem",
    [theme.breakpoints.down(1200)]: {
      gap: "1.5rem 1.5rem",
    },
    "& .input": {
      display: "flex",
      flexDirection: "column",
    },
  },
  input: {
    width: "17.8rem",
  },
  input2: {
    width: "8rem",
  },
  containerButtons: {
    display: "flex",
    marginTop: "3rem",
    gap: "3rem",
    justifyContent: "end",
    marginRight: "1.2rem",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column-reverse",
      gap: "1rem",
    },
    "& .button": {
      width: "17.8rem",
    },
  },
}));
export default useStyles;
