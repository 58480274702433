import useGeneralStyles from "@components/Services/styles";
import React from "react";
import useStyles from "./FamilyDebt.styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import { useMediaQuery } from "@material-ui/core";

interface ConsumptioOptionsProps {
  setStepDebtFamily: (value: number) => void;
  setFinishSave: (value: any) => void;
}

const FamilyDebt: React.FC<ConsumptioOptionsProps> = ({
  setStepDebtFamily,
  setFinishSave,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();

  const list = [
    {
      id: 0,
      name: "Voy a evaluar la situación",
      onClick: () => {
        setFinishSave({
          Notification48hs: false,
          Notification24hs: false,
          Notification: true,
        });
      },
    },
    {
      id: 1,
      name: "Cotizar un crédito",
      onClick: () => {
        setStepDebtFamily(1);
      },
    },
  ];
  const isMobile = useMediaQuery("(max-width: 1300px)");

  return (
    <>
      <section className={classesGeneral.container}>
        <section className={classes.subContainerCenter}>
          <div className={`${classesGeneral.secondary}`}>
            Si le debes a un familiar o amigo, intenta{" "}
            <span>repactar una forma de pago,</span> para evitar endeudarte en
            otras condiciones. {isMobile && <br />} Sino, podemos cotizar tu
            solicitud de todas formas.
          </div>
          <div className={classes.flex}>
            {list.map((item) => (
              <ServiceButton
                onClick={() => {
                  item.onClick();
                }}
                key={item.id}
                name={item.name}
              />
            ))}
          </div>
        </section>
      </section>
    </>
  );
};
export default FamilyDebt;
