import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useContext, useState } from "react";
import { ButtonPrimary } from "@components/Reusables";
import { TextInput } from "@components/Inputs";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";

import { useForm } from "@hooks";
import useStyles from "../../../../../../../LookingFor/Consumption/ConsumptionStep1/ConsumptionStep1.styles";
import { useApolloClient } from "@apollo/client";
import { LoadingContext } from "@context";
import { useCurrentUser } from "@apollo";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface ConsumptioOptionsProps {
  setOpenCreditAmount: (value: boolean) => void;
  setFinishSave: (value: any) => void;
  setStepTrip: (v: number) => void;
  stepTrip: number;
  setExplainCreditReason: (v: boolean) => void;
}

const TripOptions: React.FC<ConsumptioOptionsProps> = ({
  setOpenCreditAmount,
  setFinishSave,
  setStepTrip,
  stepTrip,
  setExplainCreditReason,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [servicesSelect, setServicesSelect] = useState(-1);

  const [form] = useForm({});

  const [destination, setDestination] = useState("");
  const [origin, setOrigin] = useState("");
  const [exit, setExit] = useState("");
  const [adults, setAdults] = useState("");
  const [children, setChildren] = useState("");
  const [conditions, setConditions] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [flights, setFlights] = useState("");

  const disabled = !!(
    destination &&
    origin &&
    adults &&
    exit &&
    children &&
    conditions &&
    returnDate &&
    flights
  );

  return stepTrip === -1 ? (
    <section className={classesGeneral.container}>
      <section className={classes.subContainerCenterTrip}>
        <div className={`${classesGeneral.secondary} ${classes.center}`}>
          No siempre es bueno pedir un crédito para viajar. Si estas seguro,
          podemos <span>evaluar tu crédito,</span> sino podemos hacer una{" "}
          <span>cotización de tu viaje.</span>
        </div>
        <div className={classes.flexTrip}>
          <ServiceButton
            onClick={() => {
              setStepTrip(0);
              setOpenCreditAmount(true);
            }}
            name={"Evaluar un crédito"}
          />
          <ServiceButton
            onClick={() => {
              setStepTrip(1);
            }}
            name={"Cotizar viaje"}
          />
        </div>
      </section>
    </section>
  ) : stepTrip === 1 ? (
    <section className={classesGeneral.container}>
      <div className={`${classesGeneral.secondary} ${classes.secondary}`}>
        Para cotizar tu viaje, cuéntanos...
      </div>
      <div className={classes.flexList1}>
        <div className={classes.flexTrip2}>
          <span className={classesGeneral.secondaryTitle}>Destino</span>
          <TextInput
            className="inputLarge"
            type={"text"}
            onChange={(e) => {
              setDestination(e?.target?.value as string);
              form.newConsumptionCredit.cotizarViaje = {
                ...form.newConsumptionCredit.cotizarViaje,
                destino: e.target.value as string,
              };
            }}
            value={destination}
          />
        </div>
        <div className={classes.flexTrip2}>
          <span className={classesGeneral.secondaryTitle}>Origen</span>
          <TextInput
            className="inputLarge"
            type={"text"}
            onChange={(e) => {
              setOrigin(e?.target?.value as string);
              form.newConsumptionCredit.cotizarViaje = {
                ...form.newConsumptionCredit.cotizarViaje,
                origen: e.target.value as string,
              };
            }}
            value={origin}
          />
        </div>
        <div className={classes.subform}>
          <div className={classes.flexTrip2}>
            <span className={classesGeneral.secondaryTitle}>Salida</span>
            <TextInput
              className="inpuShort"
              type={"date"}
              onChange={(e) => {
                setExit(e?.target?.value as string);

                form.newConsumptionCredit.cotizarViaje = {
                  ...form.newConsumptionCredit.cotizarViaje,
                  salida: e.target.value as string,
                };
              }}
              value={exit}
            />
          </div>
          <div className={classes.flexTrip2}>
            <span className={classesGeneral.secondaryTitle}>Regreso</span>
            <TextInput
              className="inpuShort"
              type={"date"}
              onChange={(e) => {
                setReturnDate(e?.target?.value as string);

                form.newConsumptionCredit.cotizarViaje = {
                  ...form.newConsumptionCredit.cotizarViaje,
                  regreso: e.target.value as string,
                };
              }}
              value={returnDate}
            />
          </div>
        </div>
      </div>
      <div className={classes.flexList}>
        <div className={classes.subform}>
          <div className={classes.flexTrip2}>
            <span className={classesGeneral.secondaryTitle}>N° de adultos</span>
            <TextInput
              className="inpuShort"
              type={"number"}
              onChange={(e) => {
                setAdults(e?.target?.value as string);

                form.newConsumptionCredit.cotizarViaje = {
                  ...form.newConsumptionCredit.cotizarViaje,
                  nroAdultos: e.target.value as string,
                };
              }}
              value={adults}
            />
          </div>
          <div className={classes.flexTrip2}>
            <span className={classesGeneral.secondaryTitle}>N° de niños</span>
            <TextInput
              className="inpuShort"
              type={"number"}
              onChange={(e) => {
                setChildren(e?.target?.value as string);

                form.newConsumptionCredit.cotizarViaje = {
                  ...form.newConsumptionCredit.cotizarViaje,
                  nroNinos: e.target.value as string,
                };
              }}
              value={children}
            />
          </div>{" "}
        </div>
        <div className={classes.flexTrip2}>
          <span className={classesGeneral.secondaryTitle}>
            Condiciones de viaje
          </span>
          <TextInput
            className="inputLarge"
            type={"text"}
            onChange={(e) => {
              setConditions(e?.target?.value as string);

              form.newConsumptionCredit.cotizarViaje = {
                ...form.newConsumptionCredit.cotizarViaje,
                condiciones: e.target.value as string,
              };
            }}
            value={conditions}
          />
        </div>
        <div className={classes.flexTrip2}>
          <span className={classesGeneral.secondaryTitle}>
            Solo vuelos directos
          </span>
          <TextInput
            className="inpuShort"
            type={"text"}
            onChange={(e) => {
              setFlights(e?.target?.value as string);

              form.newConsumptionCredit.cotizarViaje = {
                ...form.newConsumptionCredit.cotizarViaje,
                vuelosDirectos: e.target.value as string,
              };
            }}
            value={flights}
          />
        </div>
      </div>
      <div className={classes.containerButtonEnd}>
        <ButtonPrimary
          noDegraded
          disabled={!disabled}
          onClick={() => {
            /* onSend();
            setFinishSave({
              Notification48hs: true,
              Notification24hs: false,
            }); */
            setStepTrip(0);
            setExplainCreditReason(true);
          }}
          text="Enviar"
        />
      </div>
    </section>
  ) : (
    <> </>
  );
};
export default TripOptions;
