import React, { useState } from "react";
import ConsumptionStep1 from "./ConsumptionStep1/ConsumptionStep1";

interface AutomotiveProps {
  className?: string;
  setFinishSave: (value: any) => void;
  setTypeStep: React.Dispatch<React.SetStateAction<number>>;
}
const Consumption = ({
  className,
  setFinishSave,
  setTypeStep,
}: AutomotiveProps) => {

  return (
    <>
      <ConsumptionStep1
        setFinishSave={setFinishSave}
        setTypeStep={setTypeStep}
      />
    </>
  );
};
export default Consumption;
