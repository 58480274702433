import useGeneralStyles from "@components/Services/styles";
import React, { useState } from "react";
import useStyles from "./NoCreditAmount.styles";
import { ButtonPrimary } from "@components/Reusables";
import { TextInput } from "@components/Inputs";
import { useForm } from "@hooks";

interface ConsumptionProps {
  setOpenQuotePossibility: (value: boolean) => void;
  setExplainCreditReason: (value: boolean) => void;
  closeAll: () => void;
}

const NoCreditAmount: React.FC<ConsumptionProps> = ({
  setOpenQuotePossibility,
  setExplainCreditReason,
  closeAll,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [form] = useForm({});
  const [value, setValue] = useState("");
  return (
    <section className={classesGeneral.container}>
      <section className={classes.subContainerCenter}>
        <div
          className={`${classesGeneral.principalTitle} ${classesGeneral.noUppercase} ${classes.title}`}
        >
          Cuéntanos qué quieres realizar y haremos un presupuesto por ti, para
          luego evaluar la mejor forma de financiarlo
        </div>
        <textarea
          className={`${classes.styleSpace}`}
          onChange={(e) => {
            setValue(e.target.value as string);
          }}
          placeholder="Comentario"
        />
        <ButtonPrimary
          className={classes.primaryButton}
          text="Enviar"
          noDegraded
          disabled={!(value.length > 0)}
          onClick={() => {
            closeAll();
            setExplainCreditReason(true);
            form.newConsumptionCredit = {
              ...form.newConsumptionCredit,
              especificaciones: value,
            };
          }}
        />
      </section>
    </section>
  );
};
export default NoCreditAmount;
