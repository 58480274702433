import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useContext, useState } from "react";
import useStyles from "./Step2.styles";
import { Checkbox } from "@material-ui/core";
import { ButtonPrimary } from "@components/Reusables";
import { LevelSelector } from "@components/Inputs/SingleSelectors/Services/LevelSelector";
import { useForm } from "@hooks";
import { LoadingContext } from "@context";
import { useCurrentUser } from "@apollo";
import { useApolloClient } from "@apollo/client";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface newCreditProps {
  setFinishSave: (value: any) => void;
  setStepAutomotore: (v: number) => void;
}

const AutomotrizStep2: React.FC<newCreditProps> = ({
  setFinishSave,
  setStepAutomotore,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();

  const [form] = useForm({});
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();

  const [motivo, setMotivo] = useState({
    state1: false,
    state2: false,
    state3: false,
    state4: false,
    state5: false,
  });
  const [pasajeros, setPasajeros] = useState({
    state1: false,
    state2: false,
    state3: false,
    state4: false,
  });
  const [security, setSecurity] = useState("");
  const [diseno, setDiseno] = useState("");
  const [technology, setTechnology] = useState("");
  const [rendimiento, setRendimiento] = useState("");

  const onSave = useCallback(() => {
    form.newAutomotiveCredit = {
      ...form.newAutomotiveCredit,
      motivo: {
        "Ir a de la casa a la oficina": motivo.state1,
        "Paseos de fin de semana": motivo.state2,
        "Paseos 'Off road' (aventureros)": motivo.state3,
        Trabajar: motivo.state4,
        "Cargar muchas cosas": motivo.state5,
      },
      pasajeros: {
        "Yo sólo": pasajeros.state1,
        "Mi pareja": pasajeros.state2,
        Niños: pasajeros.state3,
        Mascotas: pasajeros.state4,
      },
      seguridad: security,
      diseno: diseno,
      tecnologia: technology,
      rendimiento: rendimiento
    };
    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.newAutomotiveCredit;
    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form]);

  const enabled =
    (motivo.state1 ||
      motivo.state2 ||
      motivo.state5 ||
      motivo.state3 ||
      motivo.state4) &&
    (pasajeros.state2 ||
      pasajeros.state1 ||
      pasajeros.state3 ||
      pasajeros.state4) &&
    security &&
    diseno &&
    technology &&
    rendimiento;

  return (
    <section className={`${classesGeneral.container} ${classes.container}`}>
      <div className={classes.flex}>
        <div className={classes.firstPart}>
          <div className={classes.section}>
            <span className={classesGeneral.secondary}>
              <span>¿Para qué buscas un auto?</span>
            </span>
            <span className={classes.secondaryTitle}>
              Ir de la casa a la oficina
              <Checkbox
                name={"cars"}
                onClick={() => {
                  setMotivo({
                    state1: !motivo.state1,
                    state2: motivo.state2,
                    state3: motivo.state3,
                    state4: motivo.state4,
                    state5: motivo.state5,
                  });
                }}
              />
            </span>
            <span className={classes.secondaryTitle}>
              Paseos de fin de semana
              <Checkbox
                name={"cars"}
                onClick={() => {
                  setMotivo({
                    state1: motivo.state1,
                    state2: !motivo.state2,
                    state3: motivo.state3,
                    state4: motivo.state4,
                    state5: motivo.state5,
                  });
                }}
              />
            </span>
            <span className={classes.secondaryTitle}>
              Paseos “off road” (aventureros)
              <Checkbox
                name={"cars"}
                onClick={() => {
                  setMotivo({
                    state1: motivo.state1,
                    state2: motivo.state2,
                    state3: !motivo.state3,
                    state4: motivo.state4,
                    state5: motivo.state5,
                  });
                }}
              />
            </span>
            <span className={classes.secondaryTitle}>
              Trabajar
              <Checkbox
                name={"cars"}
                onClick={() => {
                  setMotivo({
                    state1: motivo.state1,
                    state2: motivo.state2,
                    state3: motivo.state3,
                    state4: !motivo.state4,
                    state5: motivo.state5,
                  });
                }}
              />
            </span>
            <span className={classes.secondaryTitle}>
              Cargar muchas cosas
              <Checkbox
                name={"cars"}
                onClick={() => {
                  setMotivo({
                    state1: motivo.state1,
                    state2: motivo.state2,
                    state3: motivo.state3,
                    state4: motivo.state4,
                    state5: !motivo.state5,
                  });
                  console.log(motivo);
                }}
              />
            </span>
          </div>
          <div className={classes.section}>
            <span className={classesGeneral.secondaryTitle}>
              ¿Quiénes se subirían al auto?
            </span>
            <span className={classes.secondaryTitle}>
              Yo solo
              <Checkbox
                name={"cars"}
                onClick={() => {
                  setPasajeros({
                    state1: !pasajeros.state1,
                    state2: pasajeros.state2,
                    state3: pasajeros.state3,
                    state4: pasajeros.state4,
                  });
                }}
              />
            </span>
            <span className={classes.secondaryTitle}>
              Mi pareja
              <Checkbox
                name={"cars"}
                onClick={() => {
                  setPasajeros({
                    state1: pasajeros.state1,
                    state2: !pasajeros.state2,
                    state3: pasajeros.state3,
                    state4: pasajeros.state4,
                  });
                }}
              />
            </span>
            <span className={classes.secondaryTitle}>
              Niños
              <Checkbox
                name={"cars"}
                onClick={() => {
                  setPasajeros({
                    state1: pasajeros.state1,
                    state2: pasajeros.state2,
                    state3: !pasajeros.state3,
                    state4: pasajeros.state4,
                  });
                }}
              />
            </span>
            <span className={classes.secondaryTitle}>
              Mascotas
              <Checkbox
                name={"cars"}
                onClick={() => {
                  setPasajeros({
                    state1: pasajeros.state1,
                    state2: pasajeros.state2,
                    state3: pasajeros.state3,
                    state4: !pasajeros.state4,
                  });
                }}
              />
            </span>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classesGeneral.secondary}>
            <span>¿Cuánto valoras la SEGURIDAD?</span>
            <LevelSelector
              className="section"
              name="incomes"
              value={security}
              onChange={(e) => {
                setSecurity(e.target.value as string);
              }}
            />
          </div>{" "}
          <div className={classesGeneral.secondary}>
            <span className={classesGeneral.secondary}>
              ¿Cuánto valoras el DISEÑO?
            </span>
            <LevelSelector
              className="section"
              name="incomes"
              value={diseno}
              onChange={(e) => {
                setDiseno(e.target.value as string);
              }}
            />
          </div>{" "}
          <div className={classesGeneral.secondary}>
            <span className={classesGeneral.secondary}>
              ¿Cuánto valoras la TECNOLOGÍA?
            </span>
            <LevelSelector
              className="section"
              name="incomes"
              value={technology}
              onChange={(e) => {
                setTechnology(e.target.value as string);
              }}
            />
          </div>{" "}
          <div className={classesGeneral.secondary}>
            <span className={classesGeneral.secondary}>
              ¿Cuánto valoras el RENDIMIENTO?
            </span>
            <LevelSelector
              className="section"
              name="incomes"
              value={rendimiento}
              onChange={(e) => {
                setRendimiento(e.target.value as string);
              }}
            />
          </div>
        </div>
      </div>
      <ButtonPrimary
        className={classes.buttonPrimary}
        noDegraded
        disabled={!enabled}
        text="Enviar"
        onClick={() => {
          onSave();
          setFinishSave({ Notification48hs: false, Notification24hs: true });
        }}
      />
    </section>
  );
};
export default AutomotrizStep2;
