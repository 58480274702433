import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { useMediaQuery } from '@material-ui/core';
import { SEO } from '@components';

import LayoutServices from '@components/Services/Layout/LayoutServices';
import { Route } from '@interfaces';
import CreditsSearching from '@components/Services/Credits/Credits/Credits/Searching/Searching';
import Notification48 from '@components/Services/Credits/Credits/Credits/Searching/Notification48hs/Notification48';
import Notification24 from '@components/Services/Credits/Credits/Credits/Searching/Notification24hs/Notification24';
import Notification from '@components/Services/Credits/Credits/Credits/Searching/Notification/Notification';
import ServicesYouLookingFor from '@components/Services/LookingFor';
import RefinanceCredit from '@components/Services/Credits/Refinance/Refinance';
import Consolidate from '@components/Services/Credits/Consolidate/Consolidate';
import { useAuth } from '@hooks';

const servicesCredits: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });

  useEffect(() => {
    const location = window.location.href.split('#')[1];
    location && location === 'newCredit'
      ? setStepContinue(0)
      : location === 'refinanceCredit'
      ? setStepContinue(1)
      : location === 'consolidateCredit'
      ? setStepContinue(2)
      : setStepContinue(-1);
  }, []);
  //accidentes, automotriz atc..
  const isMobile = useMediaQuery('(max-width: 1200px)');

  //new state
  const [stepCredit, setStepCredit] = useState(0);
  const [pathRoute, setPathRoute] = useState<JSX.Element>();
  const [stepContinue, setStepContinue] = useState(-1);
  const [youLookingFor, setYouLookingFor] = useState({
    newCredit: false,
    refinance: false,
    consolidateCredit: false,
  });

  const [finishSave, setFinishSave] = useState({
    Notification48hs: false,
    Notification24hs: false,
    Notification: false || undefined,
  });

  let StepSearchingSelect;

  switch (stepContinue) {
    case -1:
      StepSearchingSelect = (
        <CreditsSearching
          setStepCredit={setStepCredit}
          setStepContinue={setStepContinue}
          setYouLookingFor={setYouLookingFor}
        />
      );
      break;
    case 0:
      StepSearchingSelect = (
        <ServicesYouLookingFor
          youLookingFor={youLookingFor}
          setFinishSave={setFinishSave}
          setStepContinue={setStepContinue}
        />
      );
      break;
    case 1:
      StepSearchingSelect = (
        <RefinanceCredit setStepContinue={setStepContinue} />
      );
      break;
    case 2:
      StepSearchingSelect = (
        <Consolidate
          setFinishSave={setFinishSave}
          setStepContinue={setStepContinue}
        />
      );
      break;
    default:
      break;
  }
  return (
    <>
      <SEO />
      {finishSave.Notification48hs && <Notification48 />}
      {finishSave.Notification24hs && <Notification24 />}
      {finishSave.Notification && <Notification />}

      {!finishSave.Notification48hs &&
        !finishSave.Notification &&
        !finishSave.Notification24hs && (
          <LayoutServices
            state={pathRoute}
            title={'CRÉDITOS'}
            key="Bottom"
            onBackClick={() => {
              setStepContinue(stepContinue - 1);
            }}
            activeServices={true}
            hiddenHeader={!isMobile}
          >
            {StepSearchingSelect}
          </LayoutServices>
        )}
    </>
  );
};

export default servicesCredits;
