import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerItems: {
    display: "flex",
    flexWrap: "wrap",
    gap: "3rem",
  },
  align: {
    textAlign: "center",
    marginBottom: "1rem",
  },
  secondaryTitle: {
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
    color: theme.palette.text.secondary,
    marginBottom: "0.5rem",
  },
  buttonPrimary: {
    margin: "2rem auto",
    display: "flex",
    width: "17rem",
  },
  firstPart: {
    width: "25rem",
    [theme.breakpoints.down(1200)]: {
      width: 300,
    },
    "&>div": {
      width: "15rem",
      [theme.breakpoints.down(1200)]: {
        width: 280,
      },
    },
  },
  container: {},
  flex: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
      width: 270,
      margin: "0 auto",
    },
    "& span": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .section": {
      width: "17rem",
    },
  },
}));
export default useStyles;
