import React, { useCallback, useContext } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./QuotePossibility.styles";
import { ButtonOutlined, ButtonPrimary } from "@components/Reusables";
import useGeneralStyles from "@components/Services/styles";
import { useForm } from "@hooks";
import { useApolloClient } from "@apollo/client";
import { LoadingContext } from "@context";
import { useCurrentUser } from "@apollo";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface AutomotiveProps {
  className?: string;
  setFinishSave: (value: any) => void;
  setScheduleOrAnalyzeStep2: (value: boolean) => void;
  setExplainCreditReason: (value: boolean) => void;
  type?: string;
}
const QuotePossibilityStep = ({
  className,
  setFinishSave,
  setScheduleOrAnalyzeStep2,
  setExplainCreditReason,
}: AutomotiveProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();
  const [form] = useForm({});

  const onSend = useCallback(() => {
    form.newConsumptionCredit = {
      ...form.newConsumptionCredit,
      quiero: "Cotizar crédito",
    };
    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.newConsumptionCredit;
    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form]);

  return (
    <section>
      <section className={classes.subContainerCenter}>
        <div
          className={`${classesGeneral.principalTitle} ${classesGeneral.noUppercase}`}
        >
          ¡Perfecto!
        </div>
        <div className={`${classesGeneral.secondary}`}>
          Te recordamos que no siempre es bueno pedir un crédito para estas
          cosas. Si no estas seguro de que sea la mejor opción, puedes pedir tu{" "}
          <span>consultoría</span> para que analicemos tu situación y mostrarte
          otras alternativas.
        </div>
        <div className={classes.containerButton}>
          <ButtonPrimary
            className="input"
            noDegraded
            text="Cotizar este crédito"
            onClick={() => {
              onSend();
              setFinishSave({
                Notification48hs: false,
                Notification24hs: true,
              });
            }}
          />{" "}
          <ButtonOutlined
            onClick={() => {
              setScheduleOrAnalyzeStep2(true);
              setExplainCreditReason(false);
            }}
            className="input"
            text="Quiero un análisis"
          />
        </div>
      </section>
    </section>
  );
};
export default QuotePossibilityStep;
