import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useContext, useEffect, useState } from "react";
import useStyles from "./Refinance.styles";
import { ButtonPrimary } from "@components/Reusables";
import { useDropzone } from "react-dropzone";
import { IconButton } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { ProfileContext } from "@context";
import { ATTACH_ICON } from "images/GoogleCloud";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import HeaderServices from "@components/Services/Layout/Header/Header";

interface RefinanceProps {
  setStepContinue: (value: number) => void; 

}

const RefinanceCredit: React.FC<RefinanceProps> = ({ setStepContinue }) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();


  return (
    <>
     <HeaderServices
            title="CRÉDITOS"
            onBackClick={() =>
             setStepContinue(-1)
            }
            steps={["Refinanciar"]}
          />
    <section className={classesGeneral.container}>
      <div className={`${classes.center} ${classes.containerText}`}>
        Con nuestra <strong>herramienta</strong> puedes solicitar una evaluación
        para ver si existen alternativas para refinanciar tus deudas.
      </div>
      <ButtonPrimary className={classes.button} noDegraded onClick={() => {navigate(Route.toolsRefinanceCredit)}} text="Ir a la herramienta" />
      <div className={classes.containerText2}>
        También puedes llegar a ella a través <br/>de <strong>Mis Finanzas > Herramientas</strong>
      </div>
    </section> </>
  );
};
export default RefinanceCredit;
