import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 4rem 5rem",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      padding: "2.3rem 2.8rem 5rem",
    },
  },
  secondary: {
    [theme.breakpoints.down(1200)]: {
      fontSize: "0.875rem",
      maxWidth: "320px",
      margin: "0 auto",
    },
  },
  containerItems: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
      gap: "2rem",
      maxWidth: "320px",
      margin: "2rem auto",
    },
  },
  title: {
    paddingBottom: "1.5rem",
    color: theme.palette.text.secondary,
    fontWeight: 300,
  },
  button: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "end",
  },
  buttonPrimary: {
    width: "18rem",
  },
  containerAll: {
    width: "37rem",
    margin: "0 auto",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
    },
  },
}));
export default useStyles;
