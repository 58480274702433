import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  firstText: {
    fontFamily: "Montserrat",
    textAlign: "center",
    fontWeight: 400,
    width: "34.5rem",
    margin: "0 auto 2rem",
    color: theme.palette.text.secondary,
    "& span": {
      fontWeight: 600,
    },
  },
  styleSpace: {
    width: "17.8rem",
    maxHeight: "9rem",
    height: "9rem",
    margin: "0 auto",
    border: `1px solid rgba(0, 0, 0, 0.25)`,
    borderRadius: 10,
    padding: "0.5rem",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(1200)]: { width: 245 },
  },
  maxWidth: {
    width: 310,
    fontSize: "1rem",
    "& span": {
      fontWeight: 500,
    },
  },
  principalTitle: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginBottom: "1.5rem",
    "&>div": {
      minWidth: "16.8rem",
      marginTop: "1rem",
      [theme.breakpoints.down(1300)]: {
        minWidth: "15rem",
      },
    },
    "& >div:first-child": {
      marginTop: 0,
    },
    [theme.breakpoints.down(1300)]: {
      fontSize: "0.875rem",
    },
  },
  container: {
    display: "flex",
    gap: "6rem",
    justifyContent: "center",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
      gap: "2rem",
    },
    "& >div": {
      [theme.breakpoints.down(1300)]: {
        width: "250px",
        margin: "0 auto",
      },
    },
  },
  secondText: {
    fontWeight: 400,
    width: "17rem",
    margin: "0 auto 2rem",
    color: theme.palette.text.secondary,
  },
  secondText2: {
    fontWeight: 400,
    width: "19rem",
    margin: "0 auto 0.5rem",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
      width: "250px",
    },
  },
  listDocuments: {
    listStyle: "inside",
    marginLeft: "2rem",
    [theme.breakpoints.down(1300)]: {
      marginLeft: "0rem",
    },
  },
  button: {
    margin: "4rem 0 0 3rem",
    display: "flex",
    gap: "3rem",
    justifyContent: "center",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
      width: "250px",
      margin: "2rem auto 0",
    },
    "& button": {
      width: "17.8rem",
      fontWeight: 300,
    },
  },
  bottomText: {
    display: "block",
    color: theme.palette.text.disabled,
    fontSize: "0.85rem",
    marginTop: "2rem",
    textAlign: "center",
  },
  boxes: {
    display: "flex",
    gap: "2rem",
    justifyContent: "center",
    padding: "0",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
      margin: "0 auto",
    },
    "&  .dropzone": {
      display: "flex",
      justifyContent: "center",
      "& > .hide": {
        display: "none",
      },
      "& > .side": {
        width: 248,
        height: 153,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `1px dashed ${theme.palette.primary.dark}`,
        /*         boxShadow: "4px 4px 12px 0 rgba(0, 0, 0, 0.75)",
         */ borderRadius: 8,
        background: "#edebeb",
        opacity: 0.8,
        position: "relative",
        cursor: "pointer",
        [theme.breakpoints.down(740)]: {
          width: 200,
        },
        "& > span": {
          fontSize: "0.75rem",
          fontWeight: 300,
          width: 69,
          textAlign: "center",
        },
        "& > img": {
          borderRadius: 8,
          objectFit: "cover",
          height: "100%",
          width: "100%",
        },
        "& >.MuiIconButton-root": {
          zIndex: 2,
          position: "absolute",
          top: 5,
          right: 5,
          margin: 0,
          color: "black",
          padding: 8,
          "& .MuiSvgIcon-root": {
            width: 22,
            height: 22,
          },
        },
      },
      "& > .frontside": {
        "& span": {
          "& img": {
            margin: "0 auto",
          },
        },
      },
      "& > .backside": {},
    },
    [theme.breakpoints.down(1136)]: {
      flexDirection: "column",
      justifyContent: "normal",
      gap: "2rem",
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      "& > .dropzone": {
        "& > .side": {
          width: "100%",
        },
        "& > .frontside": {
          marginRight: "0px !important",
          marginBottom: 40,
        },
        "& > .backside": {
          marginLeft: "0px !important",
        },
      },
    },
  },
  text: {
    width: "28rem",
    textAlign: "center",
    color: theme.palette.text.secondary,
    "& span": {
      fontWeight: "bold",
    },
  },
  iconDelete: {},
  containerCardFile: {
    border: `1px solid ${theme.palette.secondary.light}`,
    padding: "0.5rem",
    borderRadius: 10,
    "& .selectFile": {
      justifyContent: "space-between",
      gap: "3rem",
    },
    "& >div, & span": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& button": {
      justifyContent: "space-between",
    },
    "& .file": {
      justifyContent: "space-between",
      maxWidth: 268,
      height: "1.5rem",
      "& button span": {
        fontSize: "1rem",
        fontWeight: 500,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        justifyContent: "space-between",
      },
    },
  },
}));
export default useStyles;
