import useGeneralStyles from "@components/Services/styles";
import React from "react";
import useStyles from "./OtherCredit.styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import { useForm } from "@hooks";

interface OtherCreditProps {
  setStepDebt: (value: number) => void;
}

const OtherCredit: React.FC<OtherCreditProps> = ({ setStepDebt }) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [form] = useForm({});

  return (
    <>
      <section>
        <section className={classes.subContainerCenter}>
          <div className={`${classesGeneral.secondary}`}>
            Si deseas saldar otro crédito de consumo quizás es mejor repactar o
            refinanciar.
          </div>
          <div
            className={`${classesGeneral.principalTitle} ${classesGeneral.noUppercase}`}
          >
            ¿Quieres que evaluemos tu situación en detalle?
          </div>
          <div className={classes.flexTrip}>
            {list.map((item) => (
              <ServiceButton
                onClick={() => {
                  item.onClick;
                  setStepDebt(item.id);
                  form.newConsumptionCredit = {
                    ...form.newConsumptionCredit,
                    especificaciones:
                      item.id === 1
                        ? ("Quiero un análisis" as string)
                        : ("Quiero cotizar el crédito" as string),
                  };
                }}
                key={item.id}
                name={item.name}
              />
            ))}
          </div>
        </section>{" "}
      </section>
    </>
  );
};
export default OtherCredit;

const list = [
  {
    id: 1,
    name: "Si, quiero un análisis de mi deuda",
    onClick: () => {},
  },
  {
    id: 2,
    name: "No, solo quiero cotizar el crédito",
    onClick: () => {},
  },
];
