import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useContext, useEffect, useState } from "react";
import useStyles from "./Consolidate.styles";
import { ButtonPrimary } from "@components/Reusables";
import { useDropzone } from "react-dropzone";
import { IconButton } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { ProfileContext } from "@context";
import { ATTACH_ICON } from "images/GoogleCloud";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useForm } from "@hooks";
import HeaderServices from "@components/Services/Layout/Header/Header";
import { useCurrentUser } from "@apollo";
import { filesUpload } from "@services";
import { FileSpecificType } from "@interfaces";
import { useApolloClient } from "@apollo/client";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface ConsolidateProps {
  setStepContinue: (value: number) => void;
  setFinishSave: (value: any) => void;
}

const Consolidate: React.FC<ConsolidateProps> = ({
  setStepContinue,
  setFinishSave,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [stateConsolidate, setStateConsolidate] = useState(0);
  const { user: currentUser } = useCurrentUser();
  const client = useApolloClient();
  
  const { identityFiles, saveIdentityFile } = useContext(ProfileContext);
  const onDrop = useCallback(
    (type: "front" | "back") => (acceptedFiles: File[]) => {
      saveIdentityFile(acceptedFiles[0], type);
    },
    []
  );
  const frontDropzone = useDropzone({
    accept: ["application/pdf", "image/*"], 
    onDrop: onDrop("front"),
  }); 

  const name = currentUser?.name || "";
  const email = currentUser?.email || "";

  const sendData = () => {
    console.log("Files", identityFiles);
    filesUpload(identityFiles.front?.file, FileSpecificType.CMFFILE, client, currentUser?.id, 21).then((fileUrl) => {
      if (fileUrl) {
        const data = {
          fileUrl: fileUrl,
        }
        sendContactFormService(
          {
            name,
            email,
            data,
          },
          client
        ).then((resp) => {
          console.log("🚀 « resp:", resp)
          setFinishSave({
            Notification48hs: false,
            Notification24hs: true,
          });
        })
      }
    })
  }

  const [form] = useForm({});
  return (
    <>
      <HeaderServices
        title="CRÉDITOS"
        onBackClick={() =>
          stateConsolidate === 1 ? setStateConsolidate(0) : setStepContinue(-1)
        }
        steps={stateConsolidate === 0 ? ["Consolidar"] : undefined}
      />
      <section className={classesGeneral.container}>
        {stateConsolidate === 0 && (
          <>
            <div className={classes.firstText}>
              Con el servicio de Consolidación de Deudas puedes ver la
              posibilidad de
              <span> pagar una única cuota todos los meses.</span>
            </div>
            <div className={classes.secondText}>
              Evaluaremos si puedes consolidar tus créditos, y así, reducir
              costos legales y de seguros, además de tener mas control sobre tu
              crédito.
            </div>
            <ButtonPrimary
              className={classes.button}
              noDegraded
              onClick={() => {
                setStateConsolidate(stateConsolidate + 1);
                form.solicitudeConsolidateCredit = true;
              }}
              text="Quiero un análisis"
            />
            <span className={classes.bottomText}>
              Al solicitar este servicio accedes a un análisis detallado de tus
              cuentas actuales
            </span>
          </>
        )}{" "}
        {stateConsolidate === 1 && (
          <div className={classes.boxes}>
            <div className={classes.text}>
              Debes subir tu estado de <span>situación de la CMF</span> para
              <br />
              que realicemos el <span>análisis gratuitamente.</span> Luego,{" "}
              <br />
              si quieres que nosotros te ayudemos con el <br />
              trámite, te contaremos el costo de este de acuerdo
              <br /> a las deudas a consolidar y las condiciones.
            </div>
            <div className="dropzone" {...frontDropzone.getRootProps()}>
              <div className="side frontside">
                {!identityFiles.front && !frontDropzone.isDragActive && (
                  <span>
                    <img src={ATTACH_ICON} /> <br />
                    subir
                  </span>
                )}
                {!identityFiles.front && frontDropzone.isDragActive && (
                  <span>Suelta el archivo aqui</span>
                )}
                {identityFiles.front && (
                  <img src={identityFiles.front.path} alt="" />
                )}
                {identityFiles.front && (
                  <IconButton
                    className={classes.iconDelete}
                    onClick={(e) => {
                      e.preventDefault();
                      saveIdentityFile(undefined, "front");
                    }}
                  >
                    <DeleteRoundedIcon />
                  </IconButton>
                )}
              </div>
              <input {...frontDropzone.getInputProps()} />
            </div>
            <ButtonPrimary
              className={classes.button}
              noDegraded
              disabled={!identityFiles?.front}
              onClick={() => {
                sendData()
              }}
              text="Enviar"
            />
          </div>
        )}
      </section>
    </>
  );
};
export default Consolidate;
