import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  firstText: {
    fontFamily: "Montserrat",
    textAlign: "center",
    fontWeight: 400,
    width: "34.5rem",
    margin: "0 auto 2rem",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(1200)]: {
      width: 300,
      textAlign: "start",
    },
    "& span": {
      fontWeight: 600,
    },
  },
  secondText: {
    textAlign: "center",
    fontWeight: 400,
    width: "34.5rem",
    margin: "0 auto 2rem",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(1200)]: {
      width: 300,
      textAlign: "start",
      fontSize: "0.92rem",
    },
  },
  button: {
    width: "17.8rem",
    fontWeight: 300,
    margin: "0 auto",
    display: "flex",
    [theme.breakpoints.down(1200)]: {
      width: "18.8rem",
    },
  },
  bottomText: {
    display: "block",
    color: theme.palette.text.disabled,
    fontSize: "0.85rem",
    marginTop: "2rem",
    textAlign: "center",
    [theme.breakpoints.down(1200)]: {
      width: 300,
      textAlign: "start",
      margin: "2rem auto",
    },
  },
  boxes: {
    display: "grid",
    flexDirection: "column",
    justifyContent: "center",
    padding: "24px 0",
    "& > .dropzone": {
      display: "flex",
      justifyContent: "center",
      marginBottom: "3rem",
      marginTop: "3rem",
      "& > .hide": {
        display: "none",
      },
      "& > .side": {
        width: 248,
        height: 153,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `1px dashed ${theme.palette.primary.dark}`,
        /*         boxShadow: "4px 4px 12px 0 rgba(0, 0, 0, 0.75)",
         */ borderRadius: 8,
        background: "#edebeb",
        opacity: 0.8,
        position: "relative",
        cursor: "pointer",
        [theme.breakpoints.down(740)]: {
          width: 200,
        },
        "& > span": {
          fontSize: "0.75rem",
          fontWeight: 300,
          width: 69,
          textAlign: "center",
        },
        "& > img": {
          borderRadius: 8,
          objectFit: "cover",
          height: "100%",
          width: "100%",
        },
        "& >.MuiIconButton-root": {
          zIndex: 2,
          position: "absolute",
          top: 5,
          right: 5,
          margin: 0,
          color: "black",
          padding: 8,
          "& .MuiSvgIcon-root": {
            width: 22,
            height: 22,
          },
        },
      },
      "& > .frontside": {
        "& span": {
          "& img": {
            margin: "0 auto",
          },
        },
      },
      "& > .backside": {},
    },
    [theme.breakpoints.down(1136)]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "normal",
      gap: "2rem",
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      "& > .dropzone": {
        "& > .side": {
          width: "100%",
        },
        "& > .frontside": {
          marginRight: "0px !important",
          marginBottom: 40,
        },
        "& > .backside": {
          marginLeft: "0px !important",
        },
      },
    },
  },
  text: {
    width: "28rem",
    textAlign: "center",
    color: theme.palette.text.secondary,
    "& span": {
      fontWeight: "bold",
    },
  },
}));
export default useStyles;
