import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem 2.5rem",
    "& .input": {
      display: "flex",
      flexDirection: "column",
    },
  },
  general: {
    margin: "0 auto",
  },
  listServices: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem 2.5rem",
    [theme.breakpoints.down(1200)]: {
      justifyContent: "center",
    },
  },
  data: {
    margin: "0 auto",
    width: "206px",
  },
  containerButton: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "2rem",
    "& .input": {
      width: "17.8rem!important",
      maxWidth: "auto",
    },
  },
  subContainerCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    width: "31rem",
    margin: "0 auto",
    [theme.breakpoints.down(1200)]: {
      width: 245,
      margin: "0 auto",
      fontSize: "0.875rem",
    },
  },
  subContainerCenterTrip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    width: "38rem",
    margin: "0 auto",
    [theme.breakpoints.down(1200)]: {
      width: 265,
    },
  },
  flexTrip: {
    display: "flex",
    justifyContent: "space-between",
    width: "30rem",
    gap: "2rem",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
      gap: "0rem",
      marginTop: "1rem",
      width: "100%",
    },
    "&>div": {
      minWidth: "17.75rem",
      marginTop: "2rem",
      [theme.breakpoints.down(1200)]: { marginTop: "1rem" },
    },
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "25rem",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
      gap: "2rem",
      width: 300,
      margin: "0 auto",
    },
    "&>div": {
      width: "11.25rem",
    },
  },
  flexTrip2: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
    },
    "& span": {
      marginBottom: "0.2rem",
    },
    "&>div": {
      width: "11.25rem",
    },
  },
  flexList: {
    display: "flex",
    gap: "1.9rem",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
      margin: "1rem auto",
      gap: "1rem",
      width: 300,
    },
    "& .inpuShort": {
      width: "8.1rem",
    },
    "& .inputLarge": {
      width: "17.8rem",
    },
    secondary: {
      [theme.breakpoints.down(1200)]: {
        width: 300,
        margin: "0 auto 2rem",
      },
    },
    flexList1: {
      display: "flex",
      gap: "2.2rem",
      marginBottom: "1.5rem",
      [theme.breakpoints.down(1200)]: {
        flexDirection: "column",
        margin: "0 auto",
        gap: "1rem",
        width: 300,
      },
      "& .inpuShort": {
        width: "8.2rem",
      },
      "& .inputLarge": {
        width: "17.8rem",
      },
    },
    center: {
      margin: "auto auto",
      [theme.breakpoints.down(1200)]: {
        fontSize: "0.875rem",
      },
    },
    principalTitle: {
      margin: "1.25rem auto",
      [theme.breakpoints.down(1200)]: {
        fontSize: "0.875rem",
      },
    },
    title: {
      fontSize: "0.875rem",
      color: theme.palette.text.secondary,
      width: "27rem",
      textAlign: "center",
      [theme.breakpoints.down(1200)]: {
        width: "auto",
        margin: "0 0 1rem",
      },
    },
  },
  styleSpace: {
    width: "17.8rem",
    maxHeight: "9rem",
    height: "9rem",
    margin: "0 auto",
    border: `1px solid rgba(0, 0, 0, 0.25)`,
    borderRadius: 10,
    padding: "0.5rem",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(1200)]: { width: 245 },
  },
  primaryButton: {
    margin: "2rem auto 0",
    width: "17.8rem",
    [theme.breakpoints.down(1200)]: { width: 245 },
  },
  containerButtonEnd: {
    display: "flex",
    justifyContent: "end",
    marginRight: "1rem",
    marginTop: "4rem",
    "& button": {
      width: "17.8rem",
      [theme.breakpoints.down(1200)]: {
        margin: "0 auto",
      },
    },
  },
}));
export default useStyles;
