import useGeneralStyles from "@components/Services/styles";
import React, { useEffect } from "react";
import useStyles from "../ConsumptionStep1.styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";

import ConsumptionReminder from "@components/Services/Credits/Credits/Credits/Searching/NewCredit/ConsuptionContainer/ConsumptionReminder";
import ScheduleOrAnalyze from "@components/Services/Credits/Credits/Credits/Searching/NewCredit/ConsuptionContainer/ScheduleOrAnalyze/ScheduleOrAnalyze";
import { useForm } from "@hooks";
import { ButtonPrimary } from "@components/Reusables";
import Consumption from "../..";

interface ConsumptioOptionsProps {
  setSubstep?: (value: number) => void;
  setOpenCreditAmount: (value: boolean) => void;
  setScheduleOrAnalyzeStep: (value: boolean) => void;
  setExplainCreditReason: (value: boolean) => void;
}

const RemodelingHouseStep1: React.FC<ConsumptioOptionsProps> = ({
  setSubstep,
  setOpenCreditAmount,
  setScheduleOrAnalyzeStep,
  setExplainCreditReason,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();

  const [form] = useForm({});

  return (
    <>
      <section>
        <section className={classes.subContainerCenter}>
          <div className={`${classesGeneral.secondary} ${classes.center}`}>
            ¡Qué bueno que quieras remodelar tu casa!
          </div>
          <div
            className={`${classesGeneral.principalTitle} ${classesGeneral.noUppercase} ${classes.center} ${classes.principalTitle}`}
          >
            ¿Tienes una idea de lo que te costará?
          </div>
          <div className={classes.flex}>
            <ServiceButton
              onClick={() => {
                setOpenCreditAmount(true);
                setExplainCreditReason(false);
              }}
              name={"Sí"}
            />
            <ServiceButton
              onClick={() => {
                setOpenCreditAmount(false);

                setScheduleOrAnalyzeStep(true);
              }}
              name={"No"}
            />
          </div>
        </section>
      </section>
    </>
  );
};
export default RemodelingHouseStep1;
