import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerItems: {
    display: "flex",
    flexWrap: "wrap",
    gap: "3rem",
    [theme.breakpoints.down(1300)]: {
      gap: "1rem",
      justifyContent: "center",
    },
  },
  align: {
    textAlign: "center",
    marginBottom: "1rem",
    [theme.breakpoints.down(1200)]: {
      textAlign: "start",
    },
  },
}));
export default useStyles;
