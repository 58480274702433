import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useContext, useState } from "react";
import useStyles from "./Step1.styles";
import { ButtonOutlined, ButtonPrimary } from "@components/Reusables";
import { TextInput } from "@components/Inputs";
import { useForm } from "@hooks";
import { useCurrentUser } from "@apollo";
import { LoadingContext } from "@context";
import { useApolloClient } from "@apollo/client";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface MortgageProps {
  setFinishSave: (value: any) => void;
  StepAutomotore: (value: number) => void;
}

const AutomotiveService: React.FC<MortgageProps> = ({
  setFinishSave,
  StepAutomotore,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();
  const [form] = useForm({});

  const [state, setStateCar] = useState("");
  const [mark, setMark] = useState("");
  const [modelo, setModelo] = useState("");
  const [year, setYear] = useState("");
  const [pie, setPie] = useState("");
  const [amount, setAmount] = useState("");

  const onSend = useCallback(() => {
    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: any = form.newAutomotiveCredit;
    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form]);

  return (
    <section className={classesGeneral.container}>
      <div className={classesGeneral.title}>
        ¡Siempre es bueno proteger tu auto ante accidentes e imprevistos!
      </div>
      <section className={classes.containerList}>
        <div className="input">
          <span className={classesGeneral.secondaryTitle}>Estado</span>
          <TextInput
            className={classes.input}
            onChange={(e) => {
              setStateCar(e?.target?.value as string);
              form.newAutomotiveCredit = {
                ...form.newAutomotiveCredit,
                estado: e.target.value as string,
              };
            }}
            value={state}
            name="a"
            placeholder="Nuevo"
          />
        </div>
        <div className="input">
          <span className={classesGeneral.secondaryTitle}>Marca</span>{" "}
          <TextInput
            className={classes.input}
            onChange={(e) => {
              setMark(e?.target?.value as string);
              form.newAutomotiveCredit = {
                ...form.newAutomotiveCredit,
                marca: e.target.value as string,
              };
            }}
            value={mark}
            name="a"
            placeholder="Ford"
          />
        </div>
        <div className="input">
          <span className={classesGeneral.secondaryTitle}>Modelo</span>{" "}
          <TextInput
            className={classes.input}
            onChange={(e) => {
              setModelo(e?.target?.value as string);

              form.newAutomotiveCredit = {
                ...form.newAutomotiveCredit,
                modelo: e.target.value as string,
              };
            }}
            value={modelo}
            name="a"
            placeholder="Ecosport"
          />
        </div>
        <div className="input">
          <span className={classesGeneral.secondaryTitle}>Año</span>{" "}
          <TextInput
            className={classes.input2}
            onChange={(e) => {
              setYear(e?.target?.value as string);

              form.newAutomotiveCredit = {
                ...form.newAutomotiveCredit,
                año: e.target.value as number,
              };
            }}
            value={year}
            name="a"
            type="number"
            placeholder="AAAA"
          />
        </div>
        <div className="input">
          <span className={classesGeneral.secondaryTitle}>Precio</span>{" "}
          <TextInput
            className={classes.input2}
            value={amount}
            onChange={(e) => {
              setAmount(e?.target?.value as string);

              form.newAutomotiveCredit = {
                ...form.newAutomotiveCredit,
                precio: e.target.value as number,
              };
            }}
            name="a"
            type="number"
            placeholder="Number"
          />
        </div>
        <div className="input">
          <span className={classesGeneral.secondaryTitle}>Pie</span>{" "}
          <TextInput
            className={classes.input2}
            value={pie}
            onChange={(e) => {
              setPie(e?.target?.value as string);

              form.newAutomotiveCredit = {
                ...form.newAutomotiveCredit,
                pie: e.target.value as number,
              };
            }}
            type="number"
            name="a"
            placeholder="Number"
          />
        </div>
      </section>
      <div className={classes.containerButtons}>
        <ButtonOutlined
          className="button"
          text="No sé qué auto quiero comprar"
          onClick={() => StepAutomotore(0)}
        />
        <ButtonPrimary
          className="button"
          noDegraded
          disabled={
            !(state.length > 0) ||
            !(mark.length > 0) ||
            !(modelo.length > 0) ||
            !(year.length > 0) ||
            !(pie.length > 0) ||
            !(amount.length > 0)
          }
          onClick={() => {
            form.newConsumptionCredit = {
              ...form.newConsumptionCredit,
              estado: state,
              marca: mark,
              modelo: modelo,
              año: year,
              pie: pie,
              valor: amount,
            };

            onSend();
            setFinishSave({ Notification48hs: false, Notification24hs: true });
          }}
          text="Finalizar"
        />
      </div>
    </section>
  );
};
export default AutomotiveService;
