import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem 2.5rem",

    "& .input": {
      display: "flex",
      flexDirection: "column",
    },
  },
  general: {
    margin: "0 auto",
  },
  subContainerCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    width: "40rem",
    margin: "0 auto",
    [theme.breakpoints.down(1200)]: {
      width: 282,
    },
  },
  flexTrip: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "0 auto",
    gap: "2rem",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
      gap: 0,
    },
    "&>div": {
      width: "18rem",
      margin: "0 0 2rem 0",
      flexDirection: "column",
    },
    "& .input": {
      width: "17rem",
    },
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "25rem",
    "&>div": {
      width: "11.25rem",
    },
  },
  flexTrip2: {
    display: "flex",
    flexDirection: "column",
    "& span": {
      marginBottom: "0.2rem",
    },
    "&>div": {
      width: "11.25rem",
    },
  },
  center: {
    margin: "auto auto",
  },
  principalTitle: {
    margin: "1.25rem auto",
  },
  title: {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
    width: "27rem",
    textAlign: "center",
  },
  styleSpace: {
    width: "17.8rem",
    maxHeight: "9rem",
    height: "9rem",
    margin: "0 auto",
    border: `1px solid rgba(0, 0, 0, 0.25)`,
    borderRadius: 10,
    padding: "0.5rem",
    color: theme.palette.text.secondary,
  },
  primaryButton: {
    margin: "2rem auto 0",
    width: "17.8rem",
  },
  containerButtonEnd: {
    display: "flex",
    justifyContent: "end",
    marginRight: "1rem",
    marginTop: "4rem",
    [theme.breakpoints.down(1200)]: {
      marginTop: "1rem",
    },
    "& button": {
      width: "17.8rem",
    },
  },
  centerList: {
    display: "flex",
    justifyContent: "center",
    gap: "2rem",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
    },
  },
}));
export default useStyles;
